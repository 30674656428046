<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="120px">
      <el-form-item label="岗位名称" prop="jobName" :rules="rules">
        <el-input v-model="formData.jobName" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="岗位编码" prop="jobCode" :rules="rules">
        <el-input v-model="formData.jobCode" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <!-- <el-form-item label="排序">
        <el-input v-model="model" placeholder="请输入" size="small"></el-input>
      </el-form-item> -->
      <el-form-item label="描述" prop="remark">
        <el-input style="width:500px;" type="textarea" v-model="formData.remark" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addJobAPI, editJobAPI, getJobDetailAPI } from './api'
export default {
  name: 'AddOrEdit',

  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        jobName: '',
        jobCode: '',
        remark: ''
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getJobDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editJobAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addJobAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getJobDetail() {
      this.formData = await getJobDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-form {
    .el-form-item {
      .el-input {
        width: 500px;
      }
    }
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
